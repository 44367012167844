
import { Component, Mixins } from 'vue-property-decorator';
import Page from '@/views/Page.vue';
import { AuthMixin, VuetifyMixin, AthleteApiMixin, BAIconsMixin, PurchaseMixin, StatusMixin, FormRulesMixin, AdminRoutingMixin, PaginatedTableMixin } from '@/mixins';
import { DataTableHeader } from 'vuetify';
import { BAEventModel, EventTicketModel, EventParticipantDetails, EventLocationModel } from '@/models/baEvent';
import { BAEventDetail } from '@/../types/constants/admin.routes'
import BAEventShare from '@/views/admin/BAEventShare.vue'
import TimePickerDialog from "@/components/calendar/TimePickerDialog.vue";
import FileUploadArea from '@/components/forms/FileUploadArea.vue';
import { AthleteProfileModel, BAPurchaseModel } from '@/models';
import { athleteApi } from '@/api/AthleteApi';
import { TeamModel } from '@/models/team';
import { AgreementFile } from '@/models/file/FileModel';
import { baEventApi, eventLocationApi } from '@/api/BAEventsApi';
import { ObjectCache } from '@/helpers/object-cache';
import { AthletesCacheMixin } from '@/mixins/AthletesCacheMixin';
import { notificationStore } from '@/store';

@Component({
	components: {
		Page,
        TimePickerDialog,
        FileUploadArea,
        BAEventShare
	}
})
export default class BAEventAdminDetailPage extends Mixins(BAIconsMixin, AuthMixin, VuetifyMixin, AthleteApiMixin, PurchaseMixin, StatusMixin, FormRulesMixin, AdminRoutingMixin, AthletesCacheMixin, PaginatedTableMixin) {
    loadingEvent: boolean = true;
    savingEvent: boolean = false;
    baEvent: BAEventModel = new BAEventModel;
    editEvent: BAEventModel = new BAEventModel;
    selectLocation: EventLocationModel = new EventLocationModel;
    unlimitedCapacity: boolean;

    get IsLoading(): boolean {
        return this.loadingEvent || this.savingEvent;
    }

    get TicketHeaders(): DataTableHeader<any>[] {
        return [
            {text: 'Name', value: 'name'},
			{text: 'Price $CAD', width:"150", value: 'PriceCAD'},
			{text: 'Price $US', width:"150", value: 'PriceUS'},
			{text: 'Available', width:"100", value: 'Remaining', sortable: false},
			{text: 'Capacity', width:"100", value: 'Capacity', sortable: false},
            {text: 'ID', width:"50", value: 'id', sortable: false},
            {text: "StripeID", width:"50", value: 'stripeId', sortable: false},
            {text: '', width:"50", value: 'actions', sortable: false},
        ]
    }
    get ParticipantHeaders(): DataTableHeader<any>[] {
        return [
            {text: 'Name', value: 'name'},
            {text: 'Email', value: 'email'},
            {text: 'Location', value: 'location', sortable: false},
            {text: 'Ticket', value: 'ticket', sortable: false},
            {text: '', width:"50", value: 'actions', sortable: false},
        ]
    }

    groupFilter: string = '';
    participants: EventParticipantDetails[] = [];
    async loadParticipants() {
        if( this.IsEmpty(this.baEvent) ) return;
        if( !this.viewParticipants ) return;

        // filter participants
		let filteredParticipants = this.baEvent.participants;
		if( this.IsNotEmpty(this.groupFilter) ) filteredParticipants = filteredParticipants.filter(p => p.ticketId === this.groupFilter);
		if( this.IsNotEmpty(this.search) ) filteredParticipants = filteredParticipants.filter(p => (this.IsNotEmpty(p.tags) && p.tags.includes(this.search.toLowerCase())));

        this.participants = await Promise.all(
            filteredParticipants.slice(this.FirstTableItemIndex, this.LastTableItemIndex).map(async(attendee) => {
            const attendeeDetails: EventParticipantDetails = new EventParticipantDetails().load(attendee);
            attendeeDetails.athlete = await this.AthleteRetrieve(attendee.attendeeId);
            attendeeDetails.ticket = await this.baEvent.Ticket(attendee.ticketId);
            return attendeeDetails;
        }));

        this.dataItems = this.participants;
        this.dataItemsCount = filteredParticipants.length;
    }
    async loadTable() {
        this.isLoading = true;
        this.isLoaded = false;

        await this.loadParticipants();

        this.isLoaded = true;
        this.isLoading = false;
    }

    mounted() {
        this.loadLocations();
        this.loadEvent();
    }

    get eventId(): string {        
        return this.$route.params.eventId;
    }

    locationsCache: ObjectCache<EventLocationModel> = new ObjectCache<EventLocationModel>();
    allLocations: Array<EventLocationModel> = [];
    isLoadingLocations: boolean = false;
    async loadLocations() {
        this.isLoadingLocations = true;
        const response = await eventLocationApi.queryAll({});
        this.allLocations = response.docs;
        this.locationsCache.setMultiple(this.allLocations);
        this.isLoadingLocations = false;
    }
	get Locations(): EventLocationModel[] {
		return this.allLocations;
	}
	onChangeUnlimitedCapacity() {
		this.editEvent.capacity = this.unlimitedCapacity? -1 : (( this.baEvent.capacity < 0 )? 100 : this.baEvent.capacity );
	}

    async loadEvent(): Promise<BAEventModel> {
        this.loadingEvent = true;
        this.baEvent = undefined;
        this.baEvent = await baEventApi.findById(this.eventId);
        this.baEvent.loadEx(this.locationsCache);
        this.editEvent = new BAEventModel().load(this.baEvent);
        this.editEvent.loadEx(this.locationsCache);
        this.loadParticipants();
        this.loadingEvent = false;
        return this.baEvent;
    }

    editingName: boolean = false;
    editingDate: boolean = false;
    editingLocation: boolean = false;
    editingCapacity: boolean = false;
    editingWebsite: boolean = false;
    editingShowcase: boolean = false;
    editingDescription: boolean = false;
    editingPhoto: boolean = false;
    editingLabels: boolean = false;
    editEventDate: Date;
    editEventTime: string;
    editStartDate: Date;
    editStartTime: string;
    editEndDate: Date;
    editEndTime: string;
    get IsEditing() {
        return( this.editingName || this.editingDate || this.editingLocation || this.editingCapacity || this.editingWebsite || this.editingShowcase || this.editingDescription || this.editingPhoto || this.editingLabels );
    }
    onEditDate() {
        this.editEventDate = new Date(this.editEvent.date.toDateString());
		this.editEventTime = this.getTime(this.editEvent.date);
        this.editingDate = true;
    }
    onEditLocation() {
        this.selectLocation = this.editEvent.Location;
        this.editingLocation = true;
    }
    onEditCapacity() {
        this.unlimitedCapacity = (this.editEvent.capacity < 0);
        this.editingCapacity = true;
    }
    onEditWebsite() {
        this.editingWebsite = true;
    }
    onEditShowcase() {
        this.baEvent.showcase = true;
        this.onToggleShowcase();
    }
    onToggleShowcase() {
        this.editStartDate = new Date(this.editEvent.showcaseStart.toDateString());
		this.editStartTime = this.getTime(this.editEvent.showcaseStart);
        this.editEndDate = new Date(this.editEvent.showcaseEnd.toDateString());
		this.editEndTime = this.getTime(this.editEvent.showcaseEnd);
        this.editingShowcase = true;
    }
    onEditDescription() {
        this.editingDescription = true;
    }
    onEditPhoto() {
        this.editingPhoto = true;
    }
    onCancelEdit() {
        if( !this.IsEditing ) this.gotoEventsAdmin();

        this.editEvent = new BAEventModel().load(this.baEvent);
        this.editingName = false;
        this.editingDate = false;
        this.editingLocation = false;
        this.editingCapacity = false;
        this.editingWebsite = false;
        this.editingShowcase = false;
        this.editingDescription = false;
        this.editingPhoto = false;
        this.editingLabels = false;
    }
    async saveEvent() {
        this.savingEvent = true;

        this.editingName = false;
        this.editingDate = false;
        this.editingLocation = false;
        this.editingCapacity = false;
        this.editingWebsite = false;
        this.editingShowcase = false;
        this.editingDescription = false;
        this.editingPhoto = false;
        this.editingLabels = false;
        this.editingTicketType = false;
        this.addingTicketType = false;

        await baEventApi.save(this.editEvent);
        this.loadEvent();
        this.savingEvent = false;
    }
    async onSaveEdit() {
        if( this.editingDate ) {
		    this.editEvent.date = new Date(this.formatDateSlashesYYYYMMDD(this.editEventDate, true) + ` ${this.editEventTime}`);
        }
        if( this.editingShowcase ) {
            this.editEvent.showcaseStart = new Date(this.formatDateSlashesYYYYMMDD(this.editStartDate, true) + ` ${this.editStartTime}`);
            this.editEvent.showcaseEnd = new Date(this.formatDateSlashesYYYYMMDD(this.editEndDate, true) + ` ${this.editEndTime}`);
        }
        if( this.editingLocation ) {
		    this.editEvent.location = this.selectLocation.id;
        }
        if( this.editingCapacity ) {
            if( this.unlimitedCapacity ) this.editEvent.capacity = -1;
        }
        if( this.editingShowcase ) {
            this.editEvent.showcase = this.baEvent.showcase;
        }
        await this.saveEvent()
    }

    $refs:{ ticketTypeForm: HTMLFormElement }
    editingTicketType: boolean = false;
    backupTicketType: EventTicketModel;
    editTicketType: EventTicketModel;
    editTicketTypeTitle: string = "New Ticket Type"
    addingTicketType: boolean = false;
    ticketFree: boolean = false;
    ticketUnlimitedCapacity: boolean = false;
    startEditTicketType() {
        this.ticketUnlimitedCapacity = (this.editTicketType.capacity < 0);
        this.editingTicketType = true;
    }
    async onAddStandardTickets() {
        await this.onAddTicketType('VIP');
        this.editEvent.tickets.push(this.editTicketType);
        await this.onAddTicketType('GA');
    }
    async onAddTicketType(type?: string) {
        this.editTicketTypeTitle = "New Ticket Type";
        this.editTicketType = new EventTicketModel(type);
        this.addingTicketType = true;
        this.startEditTicketType();
    }
    async onEditTicketType(ticketType: EventTicketModel) {
        if( this.IsNotEmpty(ticketType.stripeId) ) {
            alert(`Stripe does not allow editing of ticket prices. Delete this price and create a new price`);
            return;
        }

        this.editTicketTypeTitle = `Edit ${ticketType.name}`;
        this.backupTicketType = new EventTicketModel;
        Object.assign(this.backupTicketType, ticketType);
        this.editTicketType = ticketType;
        this.startEditTicketType();
    }
    async onDeleteTicketType(ticketType: EventTicketModel) {
        if( !confirm(`Delete ${ticketType.name} Ticket? This action cannot be undone`) ) return;

        ticketType.deleted = true;
        await this.saveEvent();
    }
    async onCancelEditTicketType() {
        if( this.backupTicketType ) {
            Object.assign(this.editTicketType, this.backupTicketType);
        }
        this.editingTicketType = false;
        this.addingTicketType = false;
    }
    async onSaveEditTicketType() {
        if( !this.$refs.ticketTypeForm.validate() ) {
            this.setError( "Please complete all of the required fields" );
            return;
        }

        if( this.addingTicketType ) {
            if( this.IsEmpty( this.editTicketType.price ) ) {
                this.editTicketType.price = 0;
                this.editTicketType.priceUS = 0;
            }
            this.editEvent.tickets.push(this.editTicketType);
        }
        await this.saveEvent();
    }
    async onChangeTicketUnlimitedCapacity() {
        this.editTicketType.capacity = this.ticketUnlimitedCapacity? -1 : ((!this.backupTicketType || this.backupTicketType.capacity < 0 )? 32 : this.backupTicketType.capacity);
        this.editTicketType.setRemaining();
    }
    async onChangeTicketFree() {
        this.editTicketType.price = this.ticketFree? 0 : ((!this.backupTicketType || this.backupTicketType.price < 1)? 395 : this.backupTicketType.price);
        this.editTicketType.priceUS = this.ticketFree? 0 : ((!this.backupTicketType || this.backupTicketType.priceUS < 1)? 395 : this.backupTicketType.priceUS);
    }

    attendeeTicket: EventTicketModel;

    addingTeam: boolean = false;
    addingTeamInProgress: boolean = false;
    teamToAdd: TeamModel = null;
    async onAddTeamMembersStart() {
        this.teamToAdd = null;
        this.attendeeTicket = new EventTicketModel;
        this.addingTeam = true;
    }
    async onAddTeamToEvent(team: TeamModel) {
        if( this.IsEmpty(team) ) return;

        this.addingTeamInProgress = true;
        for( const player of team.players ) {
            // player is of type PlayerOnTeam
            if( !player.athleteId ) continue;
            if( this.editEvent.participants.find(a => a.attendeeId === player.athleteId) ) continue;

            const athlete: AthleteProfileModel = await athleteApi.findById(player.athleteId);
            if( this.IsNotEmpty(athlete) ) {
                await this.addAttendee(athlete);
            }
        }
        this.saveEvent();
        this.addingTeamInProgress = false;
        this.addingTeam = false;
    }
    async onSelectTeamToAdd(team: TeamModel) {
        this.teamToAdd = team;
    }

    async addAttendee(athlete: AthleteProfileModel): Promise<boolean> {
        try {
            if( this.editEvent.participants.find(p => p.attendeeId === athlete.id) ) {
                notificationStore.pushSnackbarWarning({message: `Athlete not added. ${athlete.FullName} is already attending this event.`})
                return false;
            }

            // create the purchase request
            const data: Record<string,any> = this.PurchaseAthleteLabels(this.editEvent.dataLabels, athlete);
            this.purchaseRequest = new BAPurchaseModel().load({
                parentId: athlete.id,
                productId: this.editEvent.id,
                priceId: this.attendeeTicket.id,
                date: new Date(),
                requested: true,
                completed: false,
                data
            })
            await this.savePurchaseRequest();

            // complete the purchase
            const completedPurchase: BAPurchaseModel = await baEventApi.addParticipant(this.editEvent.id, this.attendeeTicket.id, athlete.id);

            // add attendee to event
            await this.editEvent.addAttendee(athlete, this.attendeeTicket);

            this.waitForStripe = false;

            notificationStore.pushSnackbarSuccess({message: `${athlete.FullName} added to this event.`});
            return true;
        } catch(e) {
            notificationStore.pushSnackbarError({message: `Error Adding Athlete: ${e}`});
        }
    }

    addingAttendee: boolean = false;
    attendeeToAdd: AthleteProfileModel = null;
    async onAddAttendeeStart() {
        this.attendeeToAdd = null;
        this.attendeeTicket = new EventTicketModel;
        this.addingAttendee = true;
    }
    async onAddAttendeeComplete(athlete: AthleteProfileModel) {
        if( await this.addAttendee(athlete) ) {
            await this.saveEvent();
        }
        this.addingAttendee = false;
    }
    async onSelectAttendeeToAdd(athlete: AthleteProfileModel) {
        this.attendeeToAdd = athlete;
    }

    viewParticipants: boolean = false;
    async onToggleViewParticipants() {
        this.viewParticipants = !this.viewParticipants;
        await this.loadTable();
    }
    async onDeleteParticipants() {
        if( !confirm(`Delete ALL participants? This action cannot be undone`) ) return;

        const success = await this.editEvent.deleteAllParticipants();
        if( success ) this.saveEvent();
    }
    async onDeleteAttendee(attendeeDetails: EventParticipantDetails) {
        if( !confirm(`Delete ${attendeeDetails.FullName}'s ${attendeeDetails.Ticket} Ticket? This action cannot be undone`) ) return;

        // remove purchase
        this.deletePurchase(attendeeDetails.attendeeId, this.editEvent.id, attendeeDetails.ticketId);

        // remove attendee from this event
        const success = await this.editEvent.deleteAttendee(attendeeDetails);
        if( success ) this.saveEvent();
    }

    newAgreement: AgreementFile = new AgreementFile('My Agreement');
    newAgreementIndex: number = 1;
    agreementUnderPreview: AgreementFile;
    agreementPreview: boolean = false;
    onRemoveAllAgreements() {
        this.editEvent.agreements = [];
    }
    onRemoveAgreement(index: number) {
        this.editEvent.agreements.splice(index, 1);
    }
    async onNewAgreement() {
        if( this.IsEmpty(this.newAgreement) ) return;
        this.editEvent.agreements.push(this.newAgreement);
        await this.saveEvent();
        this.newAgreementIndex++;
        this.newAgreement = new AgreementFile(`My Agreement ${this.newAgreementIndex}`);
    }
    async onEditedAgreement() {
        await this.saveEvent();
    }
    async onPreviewAgreement(agreement) {
        this.agreementUnderPreview = agreement;
        await this.agreementUnderPreview.read();
        this.agreementPreview = true;
    }
    onClosePreview() {
        this.agreementPreview = false;
    }

    newDataLabel: string = '';
    onRemoveAllDataLabels() {
        this.editEvent.dataLabels = [];
    }
    onRemoveDataLabel(index: number) {
        this.editEvent.dataLabels.splice(index, 1);
        this.editingLabels = true;
    }
    onNewDataLabel() {
        if( this.IsEmpty(this.newDataLabel) ) return;
        if( this.editEvent.dataLabels.includes(this.newDataLabel) ) return;

        const commentsIndex = this.editEvent.dataLabels.indexOf('Comments');
        if( commentsIndex > 0 ) {
            this.editEvent.dataLabels.splice(commentsIndex, 0, this.newDataLabel);
        } else {
            this.editEvent.dataLabels.push(this.newDataLabel);
        }
        this.editingLabels = true;
    } 

	onPreviewEvent() {
		this.$router.push({
			name: BAEventDetail,
			params: { eventId: this.baEvent.id }
		});
	}
}
